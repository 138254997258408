import { Breakpoints, QueryStrings, ScreenFormat } from '@/constants/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import OfficeArea from './OfficeArea';
import PropertyInfoArea from './PropertyInfoArea';
import ImageArea from './ImageArea';
import SimulationArea from './SimulationArea';
import * as buyer from '@/state/modules/buyer';
import { Property, PropertyStatus, VisibilityStatus } from 'openapi/kusabi-frontgw-spec';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import isAuthenticated from '@/utils/authentication';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { Routings } from '@/common/routings';
import Unpublished from './Unpublished';
import theme from '@/styles/theme';
import SubmitButton from '@/components/atoms/SubmitButton';
import { Main } from '@/components/layout/common';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { FetchStatus } from '@/models/enum/app';
import { useFetchFailedEffect } from '@/common/hooks';

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  background-color: #f7f7f7;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-top: 15px;
  }
`;

const RightBackGround = styled.div`
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 45vh solid transparent;
  border-right: 15vw solid ${theme.colors.background};
  border-bottom: 45vh solid ${theme.colors.background};
  border-left: 15vw solid transparent;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

const BackButtonWrapper = styled.div`
  width: 100%;
  max-width: 1122px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 15px;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 30px 0;
  padding-left: 96px;
  font: ${theme.fonts.bold_16_45};
  color: #222222;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding-left: 24px;
    padding-right: auto;
    background: #ffffff;
  }
`;

const ArrowIcon = styled.span`
  position: relative;
  display: inline-block;
  padding-left: 36px;
  top: -4px;

  &::before {
    content: '';
    width: 11.5px;
    height: 11.5px;
    border: 0px;
    border-bottom: solid 2px #d1a827;
    border-left: solid 2px #d1a827;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
  }
  &::after {
    content: '';
    width: 16px;
    height: 2px;
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 0px;
    background: #d1a827;
  }
`;

const InquiryButtonWrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const InquiryButton = styled(SubmitButton)`
  width: 354px;
  height: 60px;
  margin: 30px 0 82px;
  font: ${theme.fonts.bold_20_36};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin: 30px 0 60px;
  }
`;

const PropertyDetail = () => {
  const [screenFormat] = useQueryParam(QueryStrings.SCREEN_FORMAT, StringParam);
  const [propertyId] = useQueryParam(QueryStrings.PROPERTY_ID, NumberParam);

  const dispatch = useDispatch();
  const contractPropertyDetail = useSelector(buyer.selectors.getContractPropertyDetail);
  const salePropertyDetail = useSelector(buyer.selectors.getSalePropertyDetail);
  const salePropertyDetailStatus: FetchStatus = useSelector(buyer.selectors.getSalePropertyDetailStatus);

  const [property, setProperty] = useState<Property>();
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);

  const currentLocation = useLocation();

  useEffect(() => {
    if (screenFormat === ScreenFormat.contract) {
      dispatch(buyer.operations.getContractPropertyDetail(propertyId as number));
    } else {
      // 売出し中物件取得時に未ログインの場合、ログイン画面を経由させる
      (async () => {
        if (!(await isAuthenticated())) {
          dispatch(
            buyer.operations.savePath({
              redirectDist: currentLocation.pathname + currentLocation.search,
            })
          );
          navigate(Routings.login.location);
        } else {
          dispatch(buyer.operations.getSalePropertyDetail(propertyId as number));
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (screenFormat === ScreenFormat.contract) {
      setProperty(contractPropertyDetail as Property);
    } else {
      setProperty(salePropertyDetail as Property);
    }
  }, [contractPropertyDetail, salePropertyDetail]);

  useFetchFailedEffect(salePropertyDetailStatus, async () => {
    setFetchFailed(true);
  });

  const onClickBackButton = () => {
    if (history.length > 2) {
      // 物件一覧から遷移してきた場合。選択した物件の物件一覧部分を表示するように買主TOPに遷移
      history.back();
    } else {
      // 直リンクで表示した場合。買主TOPに遷移
      navigate(Routings.buyerTop.location);
    }
  };

  return (
    <Main>
      <Background>
        {property?.visibilityStatus === VisibilityStatus.PUBLISH && (
          <>
            <BackButtonWrapper>
              <BackButton type="reset" onClick={() => onClickBackButton()}>
                <ArrowIcon />
                一覧に戻る
              </BackButton>
            </BackButtonWrapper>
            <ImageArea
              title={property.mansionName}
              isNew={property.isNew}
              isPriceChanged={property.hasPriceChanged}
              propertyStatus={property.propertyStatus as PropertyStatus}
              catchCopy={property.catchCopy}
              caption={property.description}
              images={property.propertyImages}
            />
            <PropertyInfoArea property={property} />
            {property.propertyStatus === PropertyStatus.SALE && <SimulationArea simulation={property.simulation} />}
            {property.propertyStatus === PropertyStatus.SALE && (
              <OfficeArea office={property.office} agentName={property.agentName} propertyId={property.displayId} />
            )}
            <InquiryButtonWrapper>
              <InquiryButton
                label={property.propertyStatus === PropertyStatus.SALE ? '物件についてのお問合せ' : 'お問合せはこちら'}
                onClick={() =>
                  navigate(
                    Routings.buyerInquiry.location,
                    property.propertyStatus === PropertyStatus.SALE ? { state: { propertyId: property?.id } } : {}
                  )
                }
              />
            </InquiryButtonWrapper>
            <RightBackGround />
            <BuyerFooter showBanner isBuyerTop />
          </>
        )}
        {(property?.visibilityStatus === VisibilityStatus.UNPUBLISH || fetchFailed) && (
          <>
            <Unpublished />
            <BuyerFooter />
          </>
        )}
      </Background>
    </Main>
  );
};

export default PropertyDetail;

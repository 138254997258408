import React from 'react';
import PropertyDetail from '@/components/organisms/Buyer/Property';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import { Wrapper } from '@/components/layout/common';

const PropertyPage: React.VFC = () => {
  return (
    <Wrapper>
      <BuyerHeader displayPattern="all" isFromPropertyDetail={true} />
      <PropertyDetail />
    </Wrapper>
  );
};

export default withInitializeApp(PropertyPage);

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const Selected = styled.div`
  width: 10px;
  height: 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  background: #d1a827;
`;

const NonSelected = styled(Selected)`
  background: #e9e9e9;
`;

type Props = {
  totalPages: number;
  currentPage: number;
  className?: string;
};

const PageSquares = ({ totalPages, currentPage, className }: Props) => {
  const squares: JSX.Element[] = [];
  for (let i = 0; i < totalPages; i++) {
    squares.push(i === currentPage ? <Selected className={className} /> : <NonSelected className={className} />);
  }

  return <Wrapper>{squares}</Wrapper>;
};
export default PageSquares;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import SlashIcon from '../Icons/SlashIcon';
import theme from '@/styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SlashWrapper = styled.div`
  margin-right: 20px;
`;

const SubTitle = styled.div`
  width: 100%;
  font: ${theme.fonts.bold_22_33};
  color: #4c586f;
`;

type Props = {
  noSlash?: boolean;
  children: ReactNode;
  className?: string;
};

const PageSubTitle = (props: Props) => (
  <Wrapper className={props.className}>
    {props.noSlash || (
      <SlashWrapper>
        <SlashIcon />
      </SlashWrapper>
    )}
    <SubTitle>{props.children}</SubTitle>
  </Wrapper>
);
export default PageSubTitle;

import { Routings } from '@/common/routings';
import SubmitButton from '@/components/atoms/SubmitButton';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: calc(100vh - 30px - 70px - 100px);
  padding: 0 15px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: calc(100vh - 30px - 70px - 387px);
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1062px;
  max-width: 100%;
  padding: 40px 20px;
  background: #ffffff;
`;

const Message = styled.p`
  font: ${theme.fonts.normal_16_32};
  color: #333333;
`;

const Button = styled(SubmitButton)`
  width: 300px;
  max-width: 100%;
  height: 64px;
  margin-top: 30px;
`;

const Unpublished = () => {
  return (
    <Wrapper>
      <MessageWrapper>
        <Message>申し訳ございません。</Message>
        <Message>こちらの物件は掲載終了しました。</Message>
        <Button label="他の物件をさがす" onClick={() => navigate(Routings.buyerTop.location)} />
      </MessageWrapper>
    </Wrapper>
  );
};

export default Unpublished;

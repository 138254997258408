import PhoneIcon from '@/components/atoms/Icons/PhoneIcon';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import React, { memo } from 'react';
import styled from 'styled-components';
import { HighlightBox } from '../Common/StyledComponents';

const Wrapper = styled(HighlightBox)`
  width: 354px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 30px;
    padding: 30px 5px 20px;
  }
`;

const Description = styled.span`
  font: ${theme.fonts.bold_16_27};
  color: #4c586f;
`;

const Supplement = styled.span`
  font: ${theme.fonts.normal_12_27};
  color: #333333;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PhoneNumber = styled.span`
  margin-left: 10px;
  font: ${theme.fonts.black_30_45};
  color: #d1a827;
`;

const PropertyId = styled.span`
  font: ${theme.fonts.bold_16_27};
  color: #333333;
`;

type Props = {
  phoneNumber?: string;
  propertyId?: string;
};

const TelArea = ({ phoneNumber, propertyId }: Props) => {
  return (
    <Wrapper>
      <div>
        <Description>お電話でのお問合せ</Description>
      </div>
      <PhoneNumberContainer>
        <PhoneIcon />
        <PhoneNumber>{phoneNumber}</PhoneNumber>
      </PhoneNumberContainer>
      <PropertyId>物件ID：{propertyId}</PropertyId>
      <Supplement>※物件IDをお伝えいただくとスムーズです</Supplement>
    </Wrapper>
  );
};

export default memo(TelArea);

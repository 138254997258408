import { format } from 'date-fns';
import { estimatedAnnualIncome } from '@/common/businessCalc';
import PageSubTitle from '@/components/atoms/PageSubTitle';
import { Breakpoints } from '@/constants/constants';
import { numberToJpYenFormat, yyyymmToJpFormatWithBuildingAge } from '@/utils/numberFormatter';
import { Property, PropertyStatus, RentManagementStatus, RentType } from 'openapi/kusabi-frontgw-spec';
import React from 'react';
import styled from 'styled-components';
import { BorderLine, Content, ContentWrapper, DummyIcon, HighlightBox, Information } from '../Common/StyledComponents';
import { ja } from 'date-fns/locale';
import Detail from '../Common/Detail';
import theme from '@/styles/theme';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';

const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const SubleaseLabel = styled.div`
  margin-left: 20px;
  padding: 0 5px;
  background: #ffffff;
  border: 1px solid #d1a827;
  border-radius: 12px;
  font: ${theme.fonts.normal_12_22};
  color: #d1a827;
`;

const PriceAndIncome = styled(HighlightBox)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 30px 96px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 22px;
    padding: 20px 20px;
  }
`;

const LabelAndAmount = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 174px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    &:first-child {
      margin-right: 0;
    }
  }
`;

const Label = styled.span`
  margin-right: 30px;
  font: ${theme.fonts.bold_16_45};
  color: #4c586f;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 96px;
    margin-right: 64px;
  }
`;

const AmountWrapper = styled.div``;

const Amount = styled.span`
  font: ${theme.fonts.black_30_45};
  letter-spacing: 0px;
  color: #d1a827;
`;

const Unit = styled.span`
  font: ${theme.fonts.black_16_45};
  color: #d1a827;
`;

const FixedBorderLine = styled(BorderLine)`
  @media screen and (min-width: ${Breakpoints.sp + 1}px) {
    display: none;
  }
`;

const SubleaseLabelWrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-left: 140px;
  }
`;

const DetailArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 96px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    padding-left: 0;
  }
`;

const DetailWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const RightDetailWrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-top: 30px;
  }
`;

type Props = {
  property: Property;
};

const PropertyInfoArea = ({ property }: Props) => {
  return (
    <ContentWrapper>
      <Content>
        <SubTitleWrapper>
          <PageSubTitle>物件情報</PageSubTitle>
          {property.rentManagementStatus === RentManagementStatus.SUBLEASE && (
            <SubleaseLabel>サブリース（家賃保証）物件</SubleaseLabel>
          )}
        </SubTitleWrapper>

        <PriceAndIncome>
          <LabelAndAmount>
            <Label>販売価格</Label>
            <AmountWrapper>
              <Amount>{property.salesPrice && numberToJpYenFormat(property.salesPrice)}</Amount>
              <Unit>万円</Unit>
            </AmountWrapper>
          </LabelAndAmount>
          <FixedBorderLine />
          <LabelAndAmount>
            <Label>年間想定収入</Label>
            <AmountWrapper>
              <Amount>{numberToJpYenFormat(estimatedAnnualIncome(property.rentPrice) / 10000)}</Amount>
              <Unit>万円</Unit>
            </AmountWrapper>
          </LabelAndAmount>
          {property.rentType === RentType.NET_INCOME && (
            <SubleaseLabelWrapper>
              <SubleaseLabel>家賃保証収入</SubleaseLabel>
            </SubleaseLabelWrapper>
          )}
        </PriceAndIncome>

        <DetailArea>
          <DetailWrapper>
            <Detail label="所在地">{`${property.prefectureName}${property.cityName}${property.townName}`}</Detail>
            {property.propertyStatus === PropertyStatus.SALE && (
              <Detail label="沿線交通">{`${property.lineName}　${property.stationName}駅　徒歩 ${property.walkMinute} 分`}</Detail>
            )}
            <Detail label="築年月">{property.builtDate && yyyymmToJpFormatWithBuildingAge(property.builtDate)}</Detail>
            <Detail label="専有面積">{`${property.unitSize} ㎡`}</Detail>
            {property.propertyStatus === PropertyStatus.SALE && (
              <Detail label="バルコニー面積">{`${property.balconySize} ㎡`}</Detail>
            )}
            {property.propertyStatus === PropertyStatus.SALE && (
              <Detail label="間取り">{`${property.floorPlan}`}</Detail>
            )}
            {property.propertyStatus === PropertyStatus.SALE && (
              <Detail label="所在階">{`${property.story} 階 / ${property.totalStory} 階建て`}</Detail>
            )}
            {property.propertyStatus === PropertyStatus.SALE && (
              <Detail label="総戸数">{`${property.totalUnit} 戸`}</Detail>
            )}
            {property.propertyStatus === PropertyStatus.SALE && (
              <Detail label="建物構造">{`${property.structure}`}</Detail>
            )}
            {property.propertyStatus === PropertyStatus.SOLD && (
              <Detail label="成約年月">
                {property.contractDate && format(new Date(property.contractDate), 'yyyy年M月', { locale: ja })}
              </Detail>
            )}
          </DetailWrapper>
          <DetailWrapper>
            {property.propertyStatus === PropertyStatus.SALE && (
              <>
                <RightDetailWrapper>
                  <Detail label="取引態様">{`${property.transactionType}`}</Detail>
                  <Detail label="建物管理費月額">{`${numberToJpYenFormat(property.maintenanceFee)} 円 / 月`}</Detail>
                  <Detail label="修繕積立金月額">{`${numberToJpYenFormat(property.repairingFee)} 円 / 月`}</Detail>
                  <Detail label="管理方式">{`${property.maintenanceType}`}</Detail>
                  <Detail label="土地権利">{`${property.landRight}`}</Detail>
                </RightDetailWrapper>
                <RightDetailWrapper>
                  <Detail label="情報更新日">
                    {property.updatedAt && format(new Date(property.updatedAt), 'yyyy年M月d日', { locale: ja })}
                  </Detail>
                  <Detail label="次回更新予定日">
                    {property.nextUpdateDate &&
                      format(new Date(property.nextUpdateDate), 'yyyy年M月d日', { locale: ja })}
                  </Detail>
                </RightDetailWrapper>
              </>
            )}
          </DetailWrapper>
        </DetailArea>
        <Information>
          <InfoIcon color="white" />
          <p>※ バルコニー面積には、ルーフバルコニー、専用庭の面積が含まれている場合があります。</p>
        </Information>
        <Information>
          <DummyIcon />
          <p>※ 管理費月額には、ルーフバルコニー、専用庭、インターネットの使用料などが含まれている場合があります。</p>
        </Information>
      </Content>
    </ContentWrapper>
  );
};

export default PropertyInfoArea;

import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Label = styled.div`
  width: 33%;
  font: ${theme.fonts.bold_16_27};
  color: #4c586f;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_14_27};
  }
`;

const Value = styled.div`
  width: 62%;
  font: ${theme.fonts.normal_15_27};
  color: #333333;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_12_27};
  }
`;

type Props = {
  label: string;
  children?: ReactNode;
};

const Detail = ({ label, children }: Props) => (
  <Wrapper>
    <Label>{label}</Label>
    <Value>{children}</Value>
  </Wrapper>
);

export default Detail;

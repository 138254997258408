import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import { Office } from 'openapi/kusabi-frontgw-spec';
import React from 'react';
import styled from 'styled-components';
import Detail from '../Common/Detail';
import { Content, ContentWrapper, FixedSubTitle } from '../Common/StyledComponents';
import TelArea from './TelArea';

const DetailArea = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
  }
`;

const DetailWrapper = styled.div`
  width: 48%;
  padding-left: 96px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding-left: 0;
  }
`;

const OfficeName = styled.div`
  margin-bottom: 20px;
  font: ${theme.fonts.bold_20_36};
  color: #4c586f;
`;

type Props = {
  office?: Office;
  agentName?: string;
  propertyId?: string;
};

const OfficeArea = ({ office, agentName, propertyId }: Props) => {
  return (
    <ContentWrapper>
      <Content>
        <FixedSubTitle>不動産会社情報</FixedSubTitle>

        <DetailArea>
          <DetailWrapper>
            <OfficeName>{`株式会社ワークスベイ ${office?.branchOfficeName}`}</OfficeName>
            <Detail label="物件担当">{agentName}</Detail>
            <Detail label="営業時間">{`${office?.businessStartHours}〜${office?.businessEndHours}`}</Detail>
            <Detail label="定休日">{office?.regularHoliday}</Detail>
            <Detail label="所在地">{office?.address}</Detail>
            <Detail label="宅建免許番号">{office?.realEstateLicenseNumber}</Detail>
          </DetailWrapper>
          <TelArea phoneNumber={office?.phoneNumber} propertyId={propertyId} />
        </DetailArea>
      </Content>
    </ContentWrapper>
  );
};

export default OfficeArea;

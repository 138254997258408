import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import { numberToJpYenFormat } from '@/utils/numberFormatter';
import { RevenueSimulation } from 'openapi/kusabi-frontgw-spec';
import React from 'react';
import styled from 'styled-components';
import {
  BorderLine,
  Content,
  ContentWrapper,
  DummyIcon,
  FixedSubTitle,
  HighlightBox,
  Information,
} from '../Common/StyledComponents';

const DetailArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
  }
`;

const DetailWrapper = styled(HighlightBox)`
  width: 48%;
  margin-bottom: 20px;
  padding: 30px 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 66px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0 0 20px 0;
  }
`;

const Label = styled.span`
  font: ${theme.fonts.bold_16_45};
  color: ${theme.colors.black};
`;

const ValueWrapper = styled.div``;

const Value = styled.span`
  font: ${theme.fonts.black_30_45};
  color: ${theme.colors.gold};
`;

const Unit = styled.span`
  font: ${theme.fonts.black_16_45};
  color: ${theme.colors.gold};
`;

const MinusValue = styled(Value)`
  color: ${theme.colors.text};
`;

const MinusUnit = styled(Unit)`
  color: ${theme.colors.text};
`;

const FixedBorderLine = styled(BorderLine)`
  margin: -20px 0 20px 0;
`;

type Props = {
  simulation?: RevenueSimulation;
};

const SimulationArea = ({ simulation }: Props) => {
  const getDisplayPrice = (price: number): string => {
    if (price == 0) {
      return '0';
    } else {
      return numberToJpYenFormat(price * 10000);
    }
  };

  return (
    <ContentWrapper>
      <Content>
        <FixedSubTitle>収益シミュレーション</FixedSubTitle>
        <DetailArea>
          <DetailWrapper>
            <Detail>
              <Label>物件価格</Label>
              <ValueWrapper>
                <Value>{simulation?.price && numberToJpYenFormat(simulation.price * 10000)}</Value>
                <Unit>円</Unit>
              </ValueWrapper>
            </Detail>
            <Detail>
              <Label>頭金</Label>
              <ValueWrapper>
                <Value>{simulation?.downPayment && getDisplayPrice(simulation.downPayment)}</Value>
                <Unit>円</Unit>
              </ValueWrapper>
            </Detail>
            <Detail>
              <Label>ローン額</Label>
              <ValueWrapper>
                <Value>{simulation?.loanPrice && getDisplayPrice(simulation.loanPrice)}</Value>
                <Unit>円</Unit>
              </ValueWrapper>
            </Detail>
            <Detail>
              <Label>金利</Label>
              <ValueWrapper>
                <Value>{simulation?.interestRate}</Value>
                <Unit>%</Unit>
              </ValueWrapper>
            </Detail>
            <Detail>
              <Label>返済期間</Label>
              <ValueWrapper>
                <Value>{simulation?.repaymentPeriod}</Value>
                <Unit>年</Unit>
              </ValueWrapper>
            </Detail>
          </DetailWrapper>

          <DetailWrapper>
            <Detail>
              <Label>{simulation?.rentManagementFee ? '賃料' : '手取り賃料'}</Label>
              <ValueWrapper>
                {simulation?.rentPrice !== undefined && simulation?.rentPrice >= 0 && <Value>+</Value>}
                <Value>{numberToJpYenFormat(simulation?.rentPrice)}</Value>
                <Unit>円</Unit>
              </ValueWrapper>
            </Detail>
            {simulation?.rentManagementFee !== undefined && (
              <Detail>
                <Label>賃貸管理手数料</Label>
                <ValueWrapper>
                  <MinusValue>{numberToJpYenFormat(simulation?.rentManagementFee)}</MinusValue>
                  <MinusUnit>円</MinusUnit>
                </ValueWrapper>
              </Detail>
            )}
            <Detail>
              <Label>ローン返済額</Label>
              <ValueWrapper>
                <MinusValue>{numberToJpYenFormat(simulation?.loanRepaymentPrice)}</MinusValue>
                <MinusUnit>円</MinusUnit>
              </ValueWrapper>
            </Detail>
            <Detail>
              <Label>建物管理費</Label>
              <ValueWrapper>
                <MinusValue>{simulation?.maintenanceFee && numberToJpYenFormat(simulation?.maintenanceFee)}</MinusValue>
                <MinusUnit>円</MinusUnit>
              </ValueWrapper>
            </Detail>
            <Detail>
              <Label>修繕積立金</Label>
              <ValueWrapper>
                <MinusValue>{simulation?.repairingFee && numberToJpYenFormat(simulation?.repairingFee)}</MinusValue>
                <MinusUnit>円</MinusUnit>
              </ValueWrapper>
            </Detail>

            <FixedBorderLine />

            <Detail>
              <Label>月収支</Label>
              <ValueWrapper>
                {simulation?.monthlyBalancePayment !== undefined && simulation?.monthlyBalancePayment >= 0 && (
                  <Value>+</Value>
                )}
                <Value>{numberToJpYenFormat(simulation?.monthlyBalancePayment)}</Value>
                <Unit>円</Unit>
              </ValueWrapper>
            </Detail>
          </DetailWrapper>
        </DetailArea>
        <Information>
          <InfoIcon color="white" />
          <p>※ 返済額は作成日現在の金利で計算しています。実際の返済額は融資実行時の金利での計算となります。</p>
        </Information>
        <Information>
          <DummyIcon />
          <p>※ 当シミュレーションはあくまでも目安としてご利用ください。</p>
        </Information>
      </Content>
    </ContentWrapper>
  );
};

export default SimulationArea;

import PageSubTitle from '@/components/atoms/PageSubTitle';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import styled from 'styled-components';

export const HighlightBox = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  max-width: 100%;
  padding: 40px 0;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;
  z-index: 1;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 30px 15px;
    border-radius: 0;
  }
`;

export const Content = styled.div`
  width: 930px;
  max-width: 100%;
  overflow: auto;
`;

export const FixedSubTitle = styled(PageSubTitle)`
  margin-bottom: 20px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 10px;
  }
`;

export const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #cccccc;
`;

export const DummyIcon = styled.div`
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  flex-shrink: 0;
`;

export const Information = styled.div`
  font: ${theme.fonts.normal_12_22};
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  p {
    margin-left: 11px;
    padding-left: 1.2em;
    text-indent: -1.2em;
  }
`;

import PageSubTitle from '@/components/atoms/PageSubTitle';
import { Breakpoints } from '@/constants/constants';
import { PropertyImage, PropertyStatus } from 'openapi/kusabi-frontgw-spec';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Content, ContentWrapper, Information } from '../Common/StyledComponents';
import * as util from '@/common/utilities';
import PageSquares from './PageSquare';
import PropertyMarker from '../Common/Marker';
import CameraIcon from '@/components/atoms/Icons/CameraIcon';
import theme from '@/styles/theme';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';

const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const CatchCopy = styled.p`
  margin-top: 5px;
  margin-bottom: 20px;
  font: ${theme.fonts.normal_12_22};
  color: #d1a827;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 10px;
  }
`;

const ImagesArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
`;

const SelectedImageArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-right: 0;
    width: 100%;
  }
`;

const SelectedImageWrapper = styled.div`
  width: 546px;
  height: 410px;
  position: relative;
  margin: 0 auto 20px;
  border: 1px solid #cccccc;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 345px;
    max-width: 100%;
    height: 259px;
  }
`;

const SelectedImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  font: ${theme.fonts.bold_30_45};
  color: #a2aab0;
`;

const ImageChangeButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-bottom: -60px;
`;

const PreviousImageButton = styled.button`
  width: 30px;
  height: 60px;

  background: #ffffff;
  &:disabled {
    background: #e9e9e9;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    width: 2px;
    height: 13px;
    background: #d1a827;
  }
  &::before {
    transform: translate(13px, 2px) rotate(45deg);
  }
  &::after {
    transform: translate(13px, -2px) rotate(-45deg);
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    position: relative;
  }
`;

const NextImageButton = styled(PreviousImageButton)`
  &::before {
    transform: translate(13px, 2px) rotate(-45deg);
  }
  &::after {
    transform: translate(13px, -2px) rotate(45deg);
  }
`;

const Caption = styled.p`
  font: ${theme.fonts.normal_12_22};
  color: #222222;
`;

const StandbyImagesAreaPc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 470px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

const StandbyImages = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 22px;
  width: 354px;
`;

const StandbyImagesSp = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;

  @media screen and (min-width: ${Breakpoints.sp + 1}px) {
    display: none;
  }
`;

const StandbyImageWrapper = styled.div`
  width: 162px;
  height: 122px;
  border: 1px solid #cccccc;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-shrink: 0;
    width: 100px;
    height: 75.24px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const StandbyImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const Pager = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

const PreviousPageButton = styled.button`
  width: 40px;
  height: 40px;
  background: #d1a827;
  &:disabled {
    background: #e9e9e9;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    width: 2px;
    height: 13px;
    background: #ffffff;
  }
  &::before {
    transform: translate(19px, 2px) rotate(45deg);
  }
  &::after {
    transform: translate(19px, -2px) rotate(-45deg);
  }
`;

const NextPageButton = styled(PreviousPageButton)`
  &::before {
    transform: translate(19px, 2px) rotate(-45deg);
  }
  &::after {
    transform: translate(19px, -2px) rotate(45deg);
  }
`;

const STANDBY_IMAGE_LIMIT_PER_PAGE = 6;

type Props = {
  title?: string;
  isNew?: boolean;
  isPriceChanged?: boolean;
  propertyStatus: PropertyStatus;
  catchCopy?: string;
  caption?: string;
  images?: PropertyImage[];
};

const ImageArea = ({ title, isNew, isPriceChanged, propertyStatus, catchCopy, caption, images }: Props) => {
  const visibleImages = images
    ? images
        .slice()
        .sort((img1, img2) => {
          if (
            img1.displayOrder === undefined ||
            img1.displayOrder === null ||
            img2.displayOrder === undefined ||
            img2.displayOrder === null
          ) {
            return 0;
          }
          return img1.displayOrder - img2.displayOrder;
        })
        .filter((image) => image.visibility)
    : [];
  const totalPages = visibleImages ? Math.ceil(visibleImages.length / STANDBY_IMAGE_LIMIT_PER_PAGE) : 0;

  const [selectedImageNumber, setSelectedImageNumber] = useState(0);
  const onPreviousImageClick = () => {
    if (visibleImages.length !== 0) {
      setSelectedImageNumber((currentVal) => (currentVal >= 1 ? currentVal - 1 : visibleImages.length - 1));
    }
  };
  const onNextImageClick = () => {
    if (visibleImages.length !== 0) {
      setSelectedImageNumber((currentVal) => (currentVal + 1) % visibleImages.length);
    }
  };

  const [selectedPage, setSelectedPage] = useState(0);
  const onPreviousPageClick = () => setSelectedPage((clickedPage) => clickedPage - 1);
  const onNextPageClick = () => setSelectedPage((clickedPage) => clickedPage + 1);
  useEffect(() => {
    setSelectedPage(Math.floor(selectedImageNumber / STANDBY_IMAGE_LIMIT_PER_PAGE));
  }, [selectedImageNumber]);

  return (
    <ContentWrapper>
      <Content>
        <SubTitleWrapper>
          <PageSubTitle noSlash>{title}</PageSubTitle>
          <LabelWrapper>
            <PropertyMarker propertyStatus={propertyStatus} isNew={isNew} hasPriceChanged={isPriceChanged} />
          </LabelWrapper>
        </SubTitleWrapper>
        <CatchCopy>{catchCopy}</CatchCopy>
        <ImagesArea>
          <SelectedImageArea>
            <SelectedImageWrapper>
              {0 < visibleImages.length ? (
                <SelectedImage src={util.cdnPath(visibleImages[selectedImageNumber]?.s3ObjectKey)} alt="選択画像" />
              ) : (
                <NoImage>
                  <CameraIcon />
                  NO IMAGE
                </NoImage>
              )}
              <ImageChangeButtons>
                <PreviousImageButton aria-label="前の画像を表示" onClick={onPreviousImageClick} />
                <NextImageButton aria-label="次の画像を表示" onClick={onNextImageClick} />
              </ImageChangeButtons>
            </SelectedImageWrapper>
            <StandbyImagesSp>
              {visibleImages.map((image, i) => (
                <StandbyImageWrapper key={image.id}>
                  <StandbyImage
                    src={util.cdnPath(image.s3ObjectKey)}
                    onClick={() => setSelectedImageNumber(i)}
                    alt={`${selectedPage + 1}ページ目の画像${i + 1}`}
                  />
                </StandbyImageWrapper>
              ))}
            </StandbyImagesSp>
            <Caption>{caption}</Caption>
          </SelectedImageArea>
          <StandbyImagesAreaPc>
            <StandbyImages>
              {visibleImages
                .slice(selectedPage * STANDBY_IMAGE_LIMIT_PER_PAGE, (selectedPage + 1) * STANDBY_IMAGE_LIMIT_PER_PAGE)
                .map((image, i) => (
                  <StandbyImageWrapper key={image.id}>
                    <StandbyImage
                      src={util.cdnPath(image.s3ObjectKey)}
                      onClick={() => setSelectedImageNumber(i + selectedPage * STANDBY_IMAGE_LIMIT_PER_PAGE)}
                      alt={`${selectedPage + 1}ページ目の画像${i + 1}`}
                    />
                  </StandbyImageWrapper>
                ))}
            </StandbyImages>

            {visibleImages.length > 0 && (
              <Pager>
                <PreviousPageButton
                  aria-label="前の画像一覧を表示"
                  disabled={selectedPage === 0}
                  onClick={onPreviousPageClick}
                />
                <PageSquares totalPages={totalPages} currentPage={selectedPage} />
                <NextPageButton
                  aria-label="次の画像一覧を表示"
                  disabled={selectedPage >= totalPages - 1}
                  onClick={onNextPageClick}
                />
              </Pager>
            )}
          </StandbyImagesAreaPc>
        </ImagesArea>
        <Information>
          <InfoIcon color="white" />
          <p>※ 物件写真は現況を優先します。</p>
        </Information>
      </Content>
    </ContentWrapper>
  );
};

export default ImageArea;
